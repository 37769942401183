import store from "@/store";
import axios from "axios";
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus';
import Vrouter from "@/router"
import qs from 'qs';
import {nextTick} from "vue";

const appServer = axios.create({
    baseURL: '/',
    timeout: 10000,
});

// post請求時 請求dde的頭部
//axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// appServer.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// debugger
// 请求拦截器

appServer.interceptors.request.use(
    config => {
        // if (store.token) {
        //     config.headers!['Authorization'] = 'Bearer ' + store.token  // 让每个请求携带自定义token 请根据实际情况自行修改
        // } else {
        //     config.headers!['Content-Type'] = "application/json;charset=utf-8"
        // }

        if (store.admintoken != '') {
            if(config.params==undefined){
                config.params = {}
            }
            config.params.token = store.admintoken;
        }

        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?';
            
            if (store.admintoken != '') {
                config.params.token = store.admintoken;
            }

            for (const propName of Object.keys(config.params)) {
                const value = config.params[propName];
                const part = encodeURIComponent(propName) + "=";
                if (value !== null && typeof (value) !== "undefined") {
                    if (typeof value === 'object') {
                        for (const key of Object.keys(value)) {
                            const params = propName + '[' + key + ']';
                            const subPart = encodeURIComponent(params) + "=";
                            url += subPart + encodeURIComponent(value[key]) + "&";
                        }
                    } else {
                        url += part + encodeURIComponent(value) + "&";
                    }
                }
            }
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }

        // post请求 data参数需要qs.stringfy
        if (config.method == 'post' && typeof config.data === "object") {

            if (config.data instanceof FormData) {
                //文件类型
                const formdata = config.data as FormData;

                if ( store.admintoken != '') {

                    formdata.append('token', store.admintoken);
                }

                config.data = formdata
            } else {
                //普通数据

                if ( store.admintoken != '') {

                    config.data.token = store.admintoken;
                }

                config.data = qs.stringify(config.data)
            }
        }

        return config;
    },
    err => {
        console.log(err)
        Promise.reject(err)
    }
);
let messageBoxShow =false;

// 响应拦截器
appServer.interceptors.response.use(
    (res) => {
       
        // 未设置状态码则默认成功状态
        const code = res.data.status || 200;
        // 获取错误信息
        const msg = res.data.text || '';

        if (code !== 200) {
            if (code === 403 ) {
                if(!messageBoxShow){
                    messageBoxShow = true;
                    ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                    ).then(() => {
                        messageBoxShow = false;
                        store.cleanLogin();
        
                        Vrouter.push('/login');
                    }).catch(()=>{
                        messageBoxShow = false;
                    })
                }
            
                
            }else {
                ElMessage({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: msg,
                    type: 'error'
                })
            }
            
            return Promise.reject('error')
        } else {
            return Promise.resolve(res.data)
        }
    },
    error => {
        console.log('err' + error)
        let { message } = error;
        if (message == "Network Error") {

            message = "后端接口连接异常";
        }
        else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        }
        else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        ElMessage({
            message: message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
);

export function download(url:string){
            const a = document.createElement("a");
          a.href = url;
          a.style.display = "none";
          a.download = "download";
          document.body.append(a);
          a.click();
          document.body.removeChild(a);
}
export function uploadFile(file:File,backData:any,uploadUrl?:string){
    const fdata = new FormData();
    fdata.append("file", file);

    appServer({
        url:  uploadUrl?uploadUrl: "/admin/upload/file",
        method: "post",
        data: fdata,

    }).then((res) => {
        const d = (res as Record<string, any>);

        if(backData){
            backData(d);
        }
    });
}

export function fullImgUrl(url:string){
    return process.env.VUE_APP_PROTOCOL+ window.location.host+url;
}
export default appServer;



